import { mapGetters, mapActions } from 'vuex'
import { currencyFormat, utcToLocal } from '@/utils'
import dayjs from 'dayjs'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
dayjs.extend(isSameOrAfter)
dayjs.extend(isSameOrBefore)

export default {
  name: 'all-programs',
  components: {
    Cash: () => import(/* webpackChunkName: "cash" */ '@/components/icons/Cash'),
    School: () => import(/* webpackChunkName: "school" */ '@/components/icons/School'),
    Installment: () => import(/* webpackChunkName: "installment" */ '@/components/icons/Installment'),
    HorizontalProduct: () => import(/* webpackChunkName: "image" */ '@/components/card/HorizontalProductCard'),
    Button: () => import(/* webpackChunkName: "button" */ '@/components/button/Button'),
    Accordion: () => import(/* webpackChunkName: "accordion" */ '@/components/accordion/Accordion'),
    ImageItem: () => import(/* webpackChunkName: "image" */ '@/components/image/ImageItem'),
    TextField: () => import(/* webpackChunkName: "textfield" */ '@/components/forms/TextField'),
    Search: () => import(/* webpackChunkName: "icon" */ '@/components/icons/Search'),
    Close: () => import(/* webpackChunkName: "icon" */ '@/components/icons/Close'),
    G2Academy: () => import(/* webpackChunkName: "g-2-academy" */ '@/components/icons/G2Academy'),
    Carousel: () => import(/* webpackChunkName: "carousel" */ '@/components/carousel/Carousel'),
    ProductCard: () => import(/* webpackChunkName: "productcard" */ '@/components/card/ProductCard'),
    ArrowForward: () => import(/* webpackChunkName: "icon" */ '@/components/icons/ArrowForward'),
    Loading: () => import(/* webpackChunkName: "illustration-loading" */ '@/components/illustration/Loading'),
    Modal: () => import(/* webpackChunkName: "modal" */ '@/components/modal/Modal'),
    TimeOutline2: () => import(/* webpackChunkName: "icon" */ '@/components/icons/TimeOutline2'),
    CheckMark: () => import(/* webpackChunkName: "icon" */ '@/components/icons/CheckMark'),
    LinkButton: () => import(/* webpackChunkName: "button" */ '@/components/button/LinkButton'),
    CreditCard: () => import(/* webpackChunkName: "credit-card" */ '@/components/icons/CreditCard'),
    Rocket: () => import(/* webpackChunkName: "rocket" */ '@/components/icons/Rocket'),
    Pagination: () => import(/* webpackChunkName: "Pagination" */ '@/components/pagination/Pagination'),
    Run: () => import(/* webpackChunkName: "run" */ '@/components/icons/Run')
  },
  data() {
    return {
      carouselPaymentOptions: {
        type: 'loop',
        autoplay: true,
        arrows: false,
        perPage: 4,
        speed: 1000,
        gap: '0.75rem',
        lazyLoad: 'nearby',
        breakpoints: {
          767: {
            perPage: 1,
            fixedWidth: '290px'
          }
        }
      },
      paymentMethod: [
        'CASH',
        'PAYASYOUGO',
        'ISA',
        'SCHOLARSHIP',
        'INSTALLMENT',
        'CREDITCARD'
      ],
      params: {
        page: 0,
        size: 100,
        class_name: ''
      },
      curratedPrograms: [],
      carouselOptions: [],
      isDataReady: false,
      classKeyword: '',
      categoryIcon: [
        {
          data: () => import(/* webpackChunkName: "icon-program" */ '@/components/icons/programs/Schedule')
        },
        {
          data: () => import(/* webpackChunkName: "icon-program" */ '@/components/icons/programs/Code')
        },
        {
          data: () => import(/* webpackChunkName: "icon-program" */ '@/components/icons/programs/Code')
        },
        {
          data: () => import(/* webpackChunkName: "icon-program" */ '@/components/icons/programs/Chart')
        },
        {
          data: () => import(/* webpackChunkName: "icon-program" */ '@/components/icons/programs/CheckMark')
        },
        {
          data: () => import(/* webpackChunkName: "icon-program" */ '@/components/icons/programs/Code')
        },
        {
          data: () => import(/* webpackChunkName: "icon-program" */ '@/components/icons/programs/Business')
        },
        {
          data: () => import(/* webpackChunkName: "icon-program" */ '@/components/icons/programs/Settings')
        },
        {
          data: () => import(/* webpackChunkName: "icon-program" */ '@/components/icons/programs/Rocket')
        }
      ],
      activeTab: 'all',
      isDataByCategoryReady: false,
      currentPage: 1,
      pageSize: 8,
      totalRecords: 0,
      classListByCategory: []
    }
  },
  metaInfo() {
    return {
      title: this.$route.meta.title,
      meta: this.$route.meta.metaTags
    }
  },
  mounted() {
    this.getCurratedPrograms()
    this.getListOfProgramCategory()
    this.getProgramListByCategory()
  },
  computed: {
    ...mapGetters('auth', ['isLoggedIn']),
    ...mapGetters('marketplace', ['cartId']),
    ...mapGetters('common', ['isMobile', 'adminB2C', 'adminGeneral']),
    ...mapGetters('seo', ['seoContent']),
    ...mapGetters('program', ['programCategoryList', 'programByCategory']),
    activeLanguange() {
      return this.$i18n.locale
    },
    bilingualPayment() {
      return this.$i18n.messages[this.activeLanguange].programDetails.payment
    },
    contructedPaymentMethod() {
      return this.paymentMethod.map(payment => {
        return {
          title: this.bilingualPayment[payment].title,
          desc: this.bilingualPayment[payment].desc
        }
      })
    },
    studentId() {
      return localStorage.getItem('student_id')
    },
    maxNameSize() {
      if (this.isMobile) return 50
      return 95
    },
    start() {
      return (this.currentPage - 1) * this.pageSize + 1
    },
    end() {
      const lastOnPage = (this.currentPage - 1) * this.pageSize + this.pageSize
      return lastOnPage > this.totalRecords ? this.totalRecords : lastOnPage
    }
  },
  watch: {},
  filters: {
    currencyFormat
  },
  methods: {
    ...mapActions('common', ['showLoading', 'hideLoading', 'sendWhatsApp']),
    ...mapActions('program', ['getProgramByWebSlug', 'getProgramCategoryList', 'getProgramByCategory']),
    ...mapActions('student', ['getStudentDetail']),
    ...mapActions('home', ['getProgramContent']),
    doSearch() {
      this.currentPage = 1
      this.getProgramListByCategory(this.categoryIds)
    },
    resetKeyword() {
      this.classKeyword = ''
      this.doSearch()
    },
    onPageChange(page) {
      this.currentPage = page
      this.getProgramListByCategory(this.categoryIds)
    },
    getProgramListByCategory(categoryIds = '') {
      this.isDataByCategoryReady = false
      this.getProgramByCategory({
        params: {
          storeId: 2,
          page: this.currentPage - 1,
          size: this.pageSize,
          q: this.classKeyword,
          categoryIds: categoryIds
        }
      }).then(async (res) => {
        this.totalRecords = res.data.data.totalRows
        const isReady = await this.getClassListWithSpecialPrice(this.programByCategory.data)
        this.isDataByCategoryReady = isReady
      })
    },
    getClassListWithSpecialPrice(classList) {
      return new Promise((resolve, reject) => {
        this.classListByCategory = classList.map(classItem => {
          const salePrice = classItem?.schedulePrices ? this.getSalePrice(classItem.schedulePrices[0]) : 0
          return {
            ...classItem,
            salePrice
          }
        })
        resolve(true)
      })
    },
    checkSalePrice(specialPrice) {
      const specialPriceType = specialPrice?.scheduleType
      if (specialPriceType === 'LIFETIME') return true
      const startDate = specialPrice?.startDate
      const endDate = specialPrice?.endDate
      const isStartDateSameAfterToday = dayjs().isSameOrAfter(utcToLocal(startDate))
      const isEndDateSameBeforeToday = dayjs().isSameOrBefore(utcToLocal(endDate))
      if (isStartDateSameAfterToday && isEndDateSameBeforeToday) return true
      return false
    },
    getSalePrice(specialPrice) {
      if (this.checkSalePrice(specialPrice)) return specialPrice?.price
      return 0
    },
    selectTab(tab) {
      this.activeTab = tab
      this.categoryIds = tab === 'all' ? '' : tab
      this.currentPage = 1
      this.getProgramListByCategory(this.categoryIds)
    },
    getListOfProgramCategory() {
      this.getProgramCategoryList({
        params: {
          storeId: 2
        }
      })
    },
    filterClassNoPrice(classes) {
      return classes.filter((item) => item.price || item.price === 0)
    },
    getCurratedPrograms() {
      this.curratedPrograms = []
      this.isDataReady = false
      this.getProgramContent({ programName: 'All Programs', params: this.params })
        .then((res) => {
          const response = res.classGroups.content
          response.map((program) => {
            const options = {
              type: 'slide',
              autoplay: false,
              pagination: true,
              arrows: false,
              keyboard: false,
              perPage: 4,
              drug: true,
              speed: 1000,
              interval: 5000,
              gap: '0.5rem',
              lazyLoad: 'nearby',
              breakpoints: {
                767: {
                  perPage: 2,
                  gap: '0'
                },
                375: {
                  perPage: 1,
                  gap: '0'
                }
              }
            }
            this.curratedPrograms.push({
              id: program.id,
              category: program.title,
              classes: program.classes
            })

            if (this.filterClassNoPrice(program.classes).length < 5) {
              options.pagination = false
            }

            if (this.filterClassNoPrice(program.classes).length >= 3 && this.isMobile) {
              options.pagination = true
            }

            this.carouselOptions.push(options)
          })
          this.isDataReady = true
        })
        .catch(() => {
          this.isDataReady = true
        })
    },
    toDetail(type, slug) {
      let program = ''
      switch (type) {
        case 'Bootcamp':
          program = 'bootcamp'
          break
        case 'Regular':
          program = 'regular'
          break
        case 'Short Course':
          program = 'regular'
          break
        default:
          program = 'prakerja'
          break
      }
      this.$router.push(`/class/${program}/${slug}`)
    },
    goToDetail(item) {
      if (item.title === 'Cash') {
        this.sendWhatsApp({ number: this.adminB2C, topic: 'Pembiayaan Kelas Secara Cash' })
      } else if (item.title === 'Credit Card' || item.title === 'Kartu Kredit') {
        this.sendWhatsApp({ number: this.adminB2C, topic: 'Pembiayaan Kelas Via Kartu Kredit' })
      } else if (item.title === 'ISA') {
        this.$router.push('/isa')
      } else if (item.title === 'Scholarship' || item.title === 'Beasiswa') {
        this.$router.push('/tech-champ-scholarship')
      } else if (item.title === 'Installment' || item.title === 'Cicilan') {
        this.$router.push('/installment')
      } else if (item.title === 'PAYASYOUGO' || item.title === 'Pay As You Go') {
        this.sendWhatsApp({ number: this.adminB2C, topic: 'Pembiayaan Kelas Secara Pay As You Go' })
      }
    }
  }
}
